import React, {PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';
import {IconEnter} from '../../Icons';
import {lighten} from 'polished';

type ChoiceStatus = 'warning' | 'success' | 'error';

type ChoiceType = PropsWithChildren<{
  small?: boolean;
  tiny?: boolean;
  icon?: boolean;
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
  statusLabel?: string;
  statusType?: ChoiceStatus;
}>;

const Choice = ({
  small,
  tiny,
  icon,
  children,
  statusType,
  statusLabel,
  ...rest
}: ChoiceType) => {
  return (
    <ChoiceContainer
      $small={small}
      $tiny={tiny}
      $hasStatus={statusType && true}
      {...rest}
    >
      {children}
      {statusLabel && (
        <StatusContainer $type={statusType}>
          <span>{statusLabel}</span>
        </StatusContainer>
      )}
      {icon && !small && (
        <ChoiceIcon>
          <IconEnter />
        </ChoiceIcon>
      )}
    </ChoiceContainer>
  );
};

export default Choice;

export const ChoiceContainer = styled.div<{
  $small?: boolean;
  $tiny?: boolean;
  $hasStatus?: boolean;
  disabled?: boolean;
  selected?: boolean;
}>`
  display: flex;
  line-height: 36px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: ${(props) => props.theme.layout.frontendSpace} 32px;
  border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
  font-size: 20px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #fff;

  &:hover {
    border-color: ${(props) => props.theme.colors.main};
  }

  & + & {
    margin-top: 20px;
    @media ${(props) => props.theme.responsive.phone} {
      margin-top: 0;
    }
  }

  @media ${(props) => props.theme.responsive.phone} {
    margin-top: 0;
    margin-bottom: 12px;
    padding: 12px 24px;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.25;
      box-shadow: none;
    `}

  ${(props) =>
    props.selected &&
    css`
      border-color: ${props.theme.colors.main};
      color: ${props.theme.colors.main};
    `}

  ${(props) =>
    props.$small &&
    css`
      justify-content: center;
      padding: ${props.theme.layout.frontendSpace} 24px;
      text-align: center;
      font-size: 18px;
      line-height: 18px;
    `}

  ${(props) =>
    props.$tiny &&
    css`
      justify-content: center;
      padding: 10px;
      font-weight: 400;
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      border-width: 1px;
      background-color: #fff;
    `}

  ${(props) =>
    props.$hasStatus &&
    css`
      position: relative;
      padding-top: 32px;
    `}
`;

const ChoiceIcon = styled.div`
  width: 36px;
  height: 36px;
`;

const StatusContainer = styled.div<{$type: ChoiceStatus}>`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 1.4;

  span {
    letter-spacing: 1px;
    font-size: 12px;
    padding-left: 32px;
    text-transform: uppercase;
    color: ${(props) => {
      switch (props.$type) {
        case 'success':
          return props.theme.colors.success;
        case 'warning':
          return props.theme.colors.warning;
        case 'error':
        default:
          return props.theme.colors.error;
      }
    }};
  }
`;
