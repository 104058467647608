import React, {PropsWithChildren} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

type SlotsProps = PropsWithChildren<{}>;

const Slots = ({children}: SlotsProps) => {
  return <SlotsContainer>{children}</SlotsContainer>;
};

Slots.propTypes = {
  children: PropTypes.node,
};

export default Slots;

const SlotsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 5px;
  position: relative;
  padding: 12px;
  z-index: 1;
  margin-top: -1px;

  @media ${(props) => props.theme.responsive.phone} {
    border-radius: 0 0 5px 5px;
  }
`;
