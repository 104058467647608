import React, {PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';
import IconCheck from './icon-check-white.svg';

type CheckboxProps = PropsWithChildren<{
  id: string;
  checked?: boolean;
  required?: boolean;
  error?: boolean;
}>;

const Checkbox = ({
  id,
  checked,
  required,
  children,
  error,
  ...props
}: CheckboxProps) => {
  return (
    <CheckboxContainer>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        required={required}
        {...props}
      />
      <Label htmlFor={id} error={error}>
        {children}
      </Label>
    </CheckboxContainer>
  );
};

export default Checkbox;

const CheckboxContainer = styled.div`
  position: relative;
  display: inline-block;
  padding-left: 26px;
  cursor: pointer;
  width: auto;

  input[type='checkbox'] {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 16px;
    left: 5px;
    opacity: 0;
  }
`;

const Label = styled.label<{error?: boolean}>`
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.label};

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background-color: white;
    border: 2px solid lightgray;
    background-position: center;
    background-repeat: no-repeat;
    transition: all ease 0.2s;
  }

  input:checked + &::after {
    background-size: 80%;
    background-image: url(${IconCheck.src});
    border-color: ${(props) => props.theme.colors.main};
    background-color: ${(props) => props.theme.colors.main};
  }

  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.colors.error};
      &::after {
        border: 2px solid ${(props) => props.theme.colors.error};
        border-radius: 5px;
      }

      input:checked + &::after {
        border-color: ${(props) => props.theme.colors.error};
      }
    `}
`;
