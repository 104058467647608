import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

type HelperProps = PropsWithChildren<{}>;

const Helper = ({children}: HelperProps) => {
  return <HelperContainer>{children}</HelperContainer>;
};

export default Helper;

const HelperContainer = styled.div`
  display: block;
  padding: 0 16px 7px 0;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 300;
  font-style: italic;
  color: ${(props) => props.theme.colors.label};
  font-family: ${(props) => props.theme.typography.main};
`;
