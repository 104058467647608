import React from 'react';
import styled from 'styled-components';
import {Label} from '../../Typography';

interface ImagePreviewProps {
  image: string;
  label: string;
  alt: string;
}

const ImagePreview = ({image, label, alt, ...props}: ImagePreviewProps) => {
  return (
    <ImagePreviewContainer>
      <Label>{label}</Label>
      <img src={image} width="200" alt={alt} />
    </ImagePreviewContainer>
  );
};

export default ImagePreview;

const ImagePreviewContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`;
