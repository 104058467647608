import React from 'react';
import styled, {css} from 'styled-components';
import {Label} from '../../Typography';
import {lighten} from 'polished';
import {Helper} from '../../FormElements';

interface ToggleInputProps {
  label?: string;
  required?: boolean;
  name: string;
  help?: string;
  disabled?: boolean;
  type?: string;
  text?: string;
  error?: string;
  checked?: boolean;
  small?: boolean;
  rounded?: boolean;
  noMargin?: boolean;
  onChange: (v) => void;
}

export default function ToggleInput({
  label,
  required,
  name,
  help,
  disabled,
  text,
  error,
  small,
  rounded,
  noMargin,
  ...props
}: ToggleInputProps) {
  return (
    <ToggleContainer small={small} noMargin={noMargin}>
      {label && (
        <Label>
          {label}
          {required && <span> *</span>}
        </Label>
      )}
      {help && <Helper>{help}</Helper>}
      <Switch withText={!!text} small={small} rounded={rounded}>
        {text && (
          <CustomStatus withText htmlFor={name} small={small}>
            {text}
          </CustomStatus>
        )}
        <InputCheckbox
          type="checkbox"
          id={name}
          disabled={disabled}
          withText={!!text}
          onChange={props.onChange}
          rounded={rounded}
          small={small}
          {...props}
        />
        <CustomLabel htmlFor={name} small={small}></CustomLabel>
        {!text && <CustomStatus small={small} htmlFor={name} />}
      </Switch>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ToggleContainer>
  );
}

const ToggleContainer = styled.div<{small?: boolean; noMargin?: boolean}>`
  margin-bottom: 20px;

  > label {
    width: 100%;
  }

  ${(props) =>
    props.noMargin &&
    css`
      margin-bottom: 0;
    `};
`;

const Switch = styled.div<{
  withText?: boolean;
  small?: boolean;
  rounded?: boolean;
}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
  padding: 7px 12px;
  width: 100%;
  height: 39px;
  border-radius: 4px;

  > p {
    margin: 0 16px 0 0;
    line-height: 1.4;
    flex: 1;
    font-size: 16px;
  }

  ${(props) =>
    props.small &&
    css`
      padding: 5px 10px;

      @media ${(props) => props.theme.responsive.tablet} {
        padding: 12px;
      }
    `};

  ${(props) =>
    props.rounded &&
    css`
      border-radius: 50px;
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
    `};
`;

const CustomLabel = styled.label<{small?: boolean}>`
  display: block;
  width: 32px;
  height: 18px;
  flex: 0 0 32px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
  position: relative;
  order: 1;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    cursor: pointer;
  }

  &:before {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.decline};
    border-radius: 9999em;
    transition: background-color 0.25s ease;
  }
  &:after {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid ${(props) => lighten(0.1, props.theme.colors.border)};
    background-color: #fff;
    transition: left 0.25s ease;
  }

  ${(props) =>
    props.small &&
    css`
      @media ${(props) => props.theme.responsive.tablet} {
        width: 30px;
        height: 16px;
        flex: 0 0 30px;

        &:after {
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
        }
      }
    `}
`;

const CustomStatus = styled.label<{withText?: boolean; small?: boolean}>`
  width: 90px;
  order: 0;
  cursor: pointer;
  user-select: none;
  line-height: 1;
  font-size: 16px;

  &:after {
    color: ${(props) => props.theme.colors.decline};
    content: 'Non attivo';
    font-size: 16px;
  }

  ${(props) =>
    props.withText &&
    css`
      width: auto;
      margin-right: 8px;
      &:after {
        content: '';
      }
    `}

  ${(props) =>
    props.small &&
    css`
      @media ${(props) => props.theme.responsive.tablet} {
        font-size: 13px;
      }
    `}
`;

const InputCheckbox = styled.input<{
  small?: boolean;
  error?: string;
  withText?: boolean;
  rounded?: boolean;
}>`
  display: none;

  &:checked + ${CustomLabel}:before {
    background-color: ${(props) => props.theme.colors.success};
  }
  &:checked + ${CustomLabel}:after {
    left: 16px;
  }
  &:checked + label + ${CustomStatus}:after {
    content: 'Attivo';
    color: ${(props) => props.theme.colors.success};
  }

  ${(props) =>
    props.withText &&
    css`
      &:checked + label + ${CustomStatus}:after {
        content: '';
      }
    `}

  ${(props) =>
    props.small &&
    css`
      @media ${(props) => props.theme.responsive.tablet} {
        &:checked + ${CustomLabel}:after {
          left: 15px;
        }
      }
    `}
`;

const ErrorMessage = styled.span`
  display: block;
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.error};
  font-family: ${(props) => props.theme.typography.main};
`;
