import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {Label} from '../../Typography';

interface TextAreaProps {
  label: string;
  disabled?: boolean;
  error?: string;
  small?: boolean;
  inline?: boolean;
  hidden?: boolean;
}

const TextArea = ({
  label,
  disabled,
  error,
  small,
  inline,
  hidden,
  ...props
}: TextAreaProps) => {
  return (
    <TextAreaField small={small} inline={inline} hidden={hidden}>
      <Label>{label}</Label>
      <TextAreaText
        disabled={disabled}
        error={!!error}
        small={small}
        {...props}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </TextAreaField>
  );
};

TextArea.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  small: PropTypes.bool,
  inline: PropTypes.bool,
};

export default TextArea;

const TextAreaField = styled.div<{
  inline?: boolean;
  hidden?: boolean;
  small?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  ${(props) =>
    props.inline &&
    css`
      display: flex;
      align-items: center;
      width: auto;

      label {
        margin-bottom: 0;
        margin-right: 8px;
        font-size: 11px;
      }
    `}

  ${(props) =>
    props.hidden &&
    css`
      margin-bottom: 0;
    `}
`;

const TextAreaText = styled.textarea<{error?: boolean; small?: boolean}>`
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: ${(props) => props.theme.typography.main};
  line-height: 16px;
  padding: 16px;
  color: ${(props) => props.theme.colors.bodyText};
  border-radius: 8px;
  background-color: #fff;
  border: solid 2px transparent;
  box-shadow: 0 0 25px ${(props) => props.theme.colors.shadow};
  outline: none;
  min-height: 90px;
  resize: vertical;

  &:focus {
    border-color: ${(props) => props.theme.colors.main};
  }

  &:disabled {
    opacity: 0.4;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.colors.error};
    `}

  ${(props) =>
    props.small &&
    css`
      height: 33px;
      padding: 4px;
      font-size: 13px;
    `}

  @media ${(props) => props.theme.responsive.phone} {
    box-shadow: 0 0 18px ${(props) => props.theme.colors.shadowMobile};
  }
`;

const ErrorMessage = styled.span`
  display: block;
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.error};
  font-family: ${(props) => props.theme.typography.main};
`;
