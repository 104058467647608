import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';
import {Choice} from '../..';

type SlotProps = PropsWithChildren<{
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
}>;

const Slot = (props: SlotProps) => {
  const {children, ...rest} = props;
  return (
    <SlotContainer>
      <Choice {...rest} small>
        {children}
      </Choice>
    </SlotContainer>
  );
};

export default Slot;

const SlotContainer = styled.div`
  display: inline-block;
  width: calc(100% / 3);
  margin: 0;
  padding: 8px;
`;
