import React from 'react';
import styled from 'styled-components';
import {ChoiceContainer} from './';

const ChoiceBlock = ({children}: any) => {
  return <ChoiceBlockContainer>{children}</ChoiceBlockContainer>;
};

export default ChoiceBlock;

export const ChoiceBlockContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  > ${ChoiceContainer} {
    flex: 0 0 48%;
    margin-right: 4%;
    margin-bottom: 20px;

    @media ${(props) => props.theme.responsive.phone} {
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 12px;
    }

    &:nth-child(even) {
      margin-right: 0;
    }
  }
  > ${ChoiceContainer} + ${ChoiceContainer} {
    margin-top: 0;
  }
`;
