import React, {useCallback, useRef, useState} from 'react';
import ImagePreview from '../ImagePreview';
import {FormCol, FormRow} from '../layout';
import BaseInput from '../Input';
import Helper from '../Helper';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export interface ImageUploaderProps {
  defaultImage?: string;
  label: string;
  previewLabel: string;
  error?: string;
  value?: string;
  help?: string;
  useCrop?: boolean;
  aspectRatio?: number;
  name: string;
  onChange?: (file: File) => void;
}

export default function ImageUploader({
  label,
  previewLabel,
  defaultImage,
  useCrop,
  aspectRatio,
  onChange,
  ...props
}: ImageUploaderProps) {
  const [image, setImage] = useState('');

  const cropperRef = useRef<HTMLImageElement>(null);
  const onCrop = async () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    const base64Response = await fetch(cropper.getCroppedCanvas().toDataURL());
    const blob = await base64Response.blob();
    const file = new File([blob], 'logo.png');
    if (onChange) {
      onChange(file);
    }
  };

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const file = input?.files?.[0];

    if (onChange && file) {
      onChange(file);
    }

    if (!file) {
      // onChange(null) ?
      return;
    }

    var reader = new FileReader();

    reader.onload = function (e) {
      e?.target?.result && setImage(e.target.result.toString());
    };

    reader.readAsDataURL(file);
  }, []);

  const imageToShow = image || defaultImage;

  return (
    <>
      <FormRow>
        <FormCol auto>
          <BaseInput
            type="file"
            accept="image/*,.jpg,.jpeg,.png"
            label={label}
            onChange={handleChange}
            {...props}
          />
        </FormCol>
        {/* show preview when not using crop */}
        {imageToShow && !useCrop && (
          <FormCol third>
            <ImagePreview
              label={previewLabel}
              image={imageToShow}
              alt={previewLabel}
            />
          </FormCol>
        )}
        {/* show preview when using crop and only before uploading a new one */}
        {useCrop && defaultImage && !image && (
          <FormCol third>
            <ImagePreview
              label={previewLabel}
              image={image || defaultImage}
              alt={previewLabel}
            />
          </FormCol>
        )}
      </FormRow>
      {useCrop && image && (
        <FormRow>
          <FormCol>
            <Helper>
              Ridimensiona o riposiziona il tuo logo per una visualizzazione
              ottimale, usa lo scroll per zoomare
            </Helper>
            <Cropper
              src={image || defaultImage}
              className={'Cropper'}
              style={{height: 400, width: '100%'}}
              // Cropper.js options
              aspectRatio={aspectRatio || 16 / 9}
              guides={false}
              cropend={onCrop}
              ref={cropperRef}
            />
          </FormCol>
        </FormRow>
      )}
    </>
  );
}
