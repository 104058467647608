import styled, {css} from 'styled-components';

export const FormRow = styled.div<{
  center?: boolean;
  align?: string;
  $margin?: boolean;
  full?: boolean;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media ${(props) => props.theme.responsive.phone} {
    flex-wrap: wrap;
  }

  ${(props) =>
    props.center &&
    css`
      align-items: center;
    `}
  ${(props) =>
    props.align === 'end' &&
    css`
      align-items: flex-end;
    `}
  ${(props) =>
    props.$margin &&
    css`
      @media ${(props) => props.theme.responsive.phone} {
        margin-bottom: ${(props) => props.theme.layout.frontendSpace};
      }
    `}
  ${(props) =>
    props.full &&
    css`
      width: 100%;
      flex: 1 1 100%;
    `}
`;

const gutter = 40;

export const FormCol = styled.div<{
  center?: boolean;
  half?: boolean;
  third?: boolean;
  auto?: boolean;
}>`
  width: 100%;

  & + & {
    padding-left: ${gutter / 2}px;

    @media ${(props) => props.theme.responsive.phone} {
      padding-left: 0;
    }
  }

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.half &&
    css`
      width: calc(50% - ${gutter / 2}px);
      @media ${(props) => props.theme.responsive.phone} {
        width: 100%;
      }
    `}

  ${(props) =>
    props.third &&
    css`
      width: calc(33.3% - ${gutter / 2}px);
      @media ${(props) => props.theme.responsive.phone} {
        width: 100%;
      }
    `}

  ${(props) =>
    props.auto &&
    css`
      width: auto;
      flex: 1 1 auto;
      padding-right: ${gutter / 2}px;

      & + & {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @media ${(props) => props.theme.responsive.phone} {
        padding-right: 0;
        width: 100%;
      }
    `}
`;
