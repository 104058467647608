import React, {PropsWithChildren} from 'react';
import styled, {css} from 'styled-components';
import Slider from 'react-slick';
import arrow from './icon-arrow-white.svg';
import {darken} from 'polished';

type DateListProps = PropsWithChildren<{}>;

const DateList = ({children}: DateListProps) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    focusOnSelect: true,
    draggable: false,
    prevArrow: <DatesArrow />,
    nextArrow: <DatesArrow flip />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Dates>
      <Slider {...settings}>{children}</Slider>
    </Dates>
  );
};

export default DateList;

const Dates = styled.div`
  position: relative;
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  padding: 10px 0 0;
  z-index: 2;

  .slick-slider {
    padding: 0 40px;

    @media ${(props) => props.theme.responsive.phone} {
      padding: 0;
    }
  }
`;

const DatesArrow = styled.button<{flip?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.theme.colors.main};
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  background-position: 8px;
  background-size: 25%;
  background-repeat: no-repeat;
  background-image: url(${arrow.src});
  transition: background-color ease 0.2s;
  z-index: 1;

  &:hover:not(.slick-disabled) {
    background-color: ${(props) => darken(0.1, props.theme.colors.main)};
  }

  &.slick-disabled {
    opacity: 0.3;
    cursor: default;
  }

  ${(props) =>
    props.flip &&
    css`
      right: 0;
      left: initial;
      transform: rotate(180deg);
    `}
`;
