const formatDate = (date) => {
  const DAYS = {
    0: 'Dom',
    1: 'Lun',
    2: 'Mar',
    3: 'Mer',
    4: 'Gio',
    5: 'Ven',
    6: 'Sab',
  };

  const MONTHS = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
  ];

  const dateToParse = new Date(date);
  const dayName = DAYS[dateToParse.getDay()];
  const dayNumber = dateToParse.getDate();
  const month = MONTHS[dateToParse.getMonth()];

  return {
    dayName,
    dayNumber,
    month,
  };
};

export default formatDate;
