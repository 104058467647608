import React from 'react';
import styled, {css} from 'styled-components';
import {Label} from '../../Typography';
import {Helper} from '..';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  type:
    | 'text'
    | 'number'
    | 'password'
    | 'email'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week'
    | 'url'
    | 'tel'
    | 'color'
    | 'range'
    | 'search'
    | 'file'
    | 'hidden'
    | 'image'
    | 'submit'
    | 'reset'
    | 'button'
    | 'checkbox'
    | 'radio';
  label?: string;
  placeholder?: string;
  value?: string | number;
  disabled?: boolean;
  error?: string;
  small?: boolean;
  inline?: boolean;
  hidden?: boolean;
  help?: string;
  required?: boolean;
  name: string;
}

const Input = ({
  label,
  disabled,
  error,
  small,
  inline,
  hidden,
  help,
  required,
  ...props
}: InputProps) => {
  return (
    <InputField
      small={small}
      inline={inline}
      hidden={hidden || props.type === 'hidden'}
    >
      {label && (
        <Label htmlFor={props.name}>
          {label}
          {required && <span> *</span>}
        </Label>
      )}
      {help && <Helper>{help}</Helper>}
      <InputText
        disabled={disabled}
        error={!!error}
        small={small}
        required={required}
        onKeyDown={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
        {...props}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputField>
  );
};

export default Input;

const InputField = styled.div<{
  inline?: boolean;
  small?: boolean;
  hidden?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  ${(props) =>
    props.inline &&
    css`
      display: flex;
      align-items: center;
      width: auto;

      label {
        margin-bottom: 0;
        margin-right: 8px;
        font-size: 11px;
      }
    `}

  ${(props) =>
    props.hidden &&
    css`
      margin-bottom: 0;
    `}
`;

const InputText = styled.input<{error?: boolean; small?: boolean}>`
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: ${(props) => props.theme.typography.main};
  line-height: 16px;
  padding: 7px 12px;
  color: ${(props) => props.theme.colors.ymGrey};
  border-radius: 4px;
  background-color: #fff;
  height: 39px;
  border: 1px solid ${(props) => props.theme.colors.ymGreyLight};
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.colors.main};
  }

  &:disabled {
    opacity: 0.4;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.colors.error};
    `}

  ${(props) =>
    props.small &&
    css`
      height: 33px;
      padding: 4px;
      font-size: 13px;
    `}

  @media ${(props) => props.theme.responsive.phone} {
    border-color: #d3d3d3;
  }
`;

const ErrorMessage = styled.span`
  display: block;
  margin: 8px 0 8px 15px;
  font-size: 13px;
  font-weight: 300;
  color: ${(props) => props.theme.colors.error};
  font-family: ${(props) => props.theme.typography.main};
`;
