import React from 'react';
import styled, {css} from 'styled-components';
import formatDate from '../../../../utils/formatDate';

interface SingleDateProps {
  date: string | number | Date;
  selected?: boolean;
  onClick?: () => void;
}

const SingleDate = (props: SingleDateProps) => {
  const {date, selected, ...rest} = props;
  const theDate = formatDate(date);
  return (
    <DateContainer selected={selected} {...rest}>
      <span>{theDate.dayName}</span>
      <span>{theDate.dayNumber}</span>
      <span>{theDate.month}</span>
    </DateContainer>
  );
};

export default SingleDate;

const DateContainer = styled.div<{selected?: boolean}>`
  position: relative;
  display: inline-block;
  text-align: center;
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  padding-top: 3px;
  margin-right: 1px;
  width: 99% !important;

  &:hover {
    color: ${(props) => props.theme.colors.main};
  }

  span {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;

    & + span {
      margin-left: 6px;
    }
  }

  @media ${(props) => props.theme.responsive.phone} {
    width: 100% !important;
  }

  ${(props) =>
    props.selected &&
    css`
      z-index: 1;
      background-color: ${(props) => props.theme.colors.background};
      border-radius: 5px 5px 0 0;
      border: 1px solid ${(props) => props.theme.colors.border};
      border-bottom: 1px solid ${(props) => props.theme.colors.background};
    `}
`;
